import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
  useMemo,
} from "react";
import { Editable, GDDImageAdd } from "pages/GDD3/Helpers";
import { useBus, useListener } from "react-bus";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ShowIf from "components/common/ShowIf";
import _ from "lodash";
import PerformanceUtils from "helpers/PerformanceUtils";
import SocketContext from "context/SocketContext";
import {
  AddCircleOutlined,
  AddOutlined,
  ArrowDropDownOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormatListBulletedOutlined,
  ImageOutlined,
} from "@mui/icons-material";
import { Menu } from "pages/GDD3/GDDGameSummary";
import {
  MENUS,
  IMAGE_MENUS,
  IMAGE_MENUS2,
} from "pages/GDD3/GDDSideMenu/GameElementsMenu";
import GDDContext from "context/GDDContext";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MaterialMenu,
} from "@material-ui/core";
import ImageGallery, { downloadImage } from "components/common/ImageGallery";
import CacheContext from "context/CacheContext";
import { GeneratedImage } from "../../components/common/GeneratedGameCard";
import ImageEditor from "../ImageGenerator/ImageEditor";

export const GAME_ELEMENT_LAYOUTS = {
  images: "images",
  text: "text",
};

const GDDGameElement = ({
  component,
  title,
  value,
  section,
  active,
  loading,
  scrollTo,
  changeGdd,
  sectionSetActiveComponent,
  gdd,
}) => {
  const ref = useRef();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (active && scrollTo && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [active, scrollTo]);

  const actionsActive = loading ? false : active;

  const [hoverText, setHoverText] = useState();

  const onHover = useCallback((data) => {
    setHoverText(data);
  }, []);

  useListener(`${section}.hover`, onHover);

  const isEmpty = (value?.elements || []).length === 0 && !value?.text;

  let className = "section game-elements";
  if (actionsActive) className += " active";
  if (isEmpty) className += " empty";
  else if (!value?.text) className += " keep-together";

  const defaultLayout = component?.images_by_default
    ? GAME_ELEMENT_LAYOUTS.images
    : GAME_ELEMENT_LAYOUTS.text;
  const layout = value?.layout || defaultLayout;
  className += ` layout-${layout}`;

  return (
    <div
      onClick={() => {
        setSelected(false);
      }}
    >
      <div className={className} ref={ref}>
        <div className="left-content">
          <span className="section-subtitle">{title}</span>
        </div>
        <div className="right-content">
          {component.multiple_allowed && (
            <MultipleItemsContent
              gdd={gdd}
              active={actionsActive}
              section={section}
              value={value}
              changeGdd={changeGdd}
              hoverText={hoverText}
              setHoverText={setHoverText}
              component={component}
              selected={selected}
              sectionSetActiveComponent={sectionSetActiveComponent}
              setSelected={(value) => {
                setSelected(value);
                if (!!value && !actionsActive) {
                  sectionSetActiveComponent();
                }
              }}
            />
          )}
          {!component.multiple_allowed && (
            <SingleItemContent
              gdd={gdd}
              active={actionsActive}
              section={section}
              value={value}
              changeGdd={changeGdd}
              hoverText={hoverText}
              component={component}
              onHover={onHover}
              selected={selected}
              setSelected={(value) => {
                setSelected(value);
                if (value && !actionsActive) {
                  sectionSetActiveComponent();
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SingleItemContent = ({
  active,
  section,
  value,
  changeGdd,
  hoverText,
  component,
  onHover,
  selected,
  setSelected,
  gdd,
}) => {
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;
  const { track } = useContext(SocketContext);
  const { menu, openMenu } = useContext(GDDContext);
  const bus = useBus();
  const [selectedPart, setSelectedPart] = useState();
  const [editImage, setEditImage] = useState();

  useEffect(() => {
    if (active && !value?.text) {
      setSelected(true);
      setSelectedPart("text");
    }
  }, [active, value]);

  const onClick = useCallback(
    (data) => {
      if (data.url) return onClickImage({ image: data });
      const { text, isNew, image } = data;
      track("gdd.elements.click-idea", { text });
      onHover();
      if (text) {
        let glue = isNew && !!value?.text ? "\n\n" : "";
        let newValue = (value?.text || "") + glue + text;
        changeText(newValue, image);
      }
    },
    [section, value, changeGdd]
  );

  const onClickImage = useCallback(
    ({ image }) => {
      if (image) {
        track("gdd.elements.click-image", { image });
        let images = value?.images || [];
        images.push(image);
        changeGdd(section, { ...(value || {}), images }, true);
      }
    },
    [section, value, selected, changeGdd]
  );

  function removeImage(image) {
    let images = value?.images || [];
    images = images.filter(({ url }) => url !== image.url);
    changeGdd(section, { ...(value || {}), images }, true);
  }

  useListener(`${section}.click`, onClick);
  useListener(`${section}.clickImage`, onClickImage);

  function onTyped(text) {
    bus.emit(`${section}.typed`, { value: text });
    changeText(text);
  }

  function changeText(text, image) {
    let newValue = { ...(value || {}), text };
    if (image) {
      newValue.images = [...(newValue.images || []), image];
    }

    changeGdd(section, newValue, true);
  }

  function onClickedOption(option) {
    openMenu({ component, option, section });
  }

  function onClickedImageOption(option, image) {
    if (option === "editImage") {
      setEditImage(image);
    } else if (option === "delete") {
      removeImage(image);
    } else if (option === "download") {
      downloadImage(image);
    } else {
      openMenu({
        component,
        option,
        section,
        element: { text: value?.text },
        id: section,
        image,
      });
    }
  }

  const imageWrapper = useCallback(
    (image, width, height, imageComponent) => {
      let id = image.id || image.url;
      return (
        <AddImage
          component={component}
          onClick={() => setSelectedPart(id)}
          onClickImage={() => setSelectedPart(id)}
          selected={selected && id === selectedPart}
          menus={IMAGE_MENUS2}
          onClickedOption={(option) => onClickedImageOption(option, image)}
          image={image}
          allowUpload={false}
          imageComponent={imageComponent}
          style={{ width, height }}
        />
      );
    },
    [selected, selectedPart]
  );

  let addOnText = hoverText?.text || "";
  if (!!addOnText && hoverText?.isNew && !!value?.text)
    addOnText = `\n\n${addOnText}`;

  const placeholder =
    gddComponents[component?.section]?.placeholder ||
    `Write about your ${component?.section} here`;

  let className = "game-element large";
  if (menu?.component?.section === section) className += " open";
  if (selected) className += " selected";

  const menus = {
    suggestions: value?.text ? MENUS.expand : MENUS.suggestions,
  };

  if (!value?.text) menus.new = MENUS.new;
  menus.chat = MENUS.chat;
  menus.favorites = MENUS.favorites;

  let textMenuWrapperClassName = "menu-wrapper";
  if (selected && selectedPart === "text")
    textMenuWrapperClassName += " selected";

  const images = useMemo(() => {
    return value?.images?.filter((image) => !!image);
  }, [value]);

  function onEditedImages(editedImages) {
    let images = value?.images || [];
    images = images.map((image) => {
      if (image.url === editImage?.url) return editedImages[0];
      return image;
    });
    changeGdd(section, { ...(value || {}), images }, true);
    setEditImage();
  }

  return (
    <>
      {!!editImage && (
        <ImageEditor
          url={editImage?.url}
          initialImage={editImage}
          onClose={() => setEditImage()}
          formValues={{
            image_type: "screenshot",
            platform: gdd.platform,
            genres: gdd.genres,
            perspective: gdd.perspective,
            art_style: gdd.art_style,
          }}
          onResults={onEditedImages}
        />
      )}
      <div
        className={className}
        onClick={(event) => {
          event.stopPropagation();
          setSelected(true);
        }}
      >
        {value?.text || images?.length > 0 ? (
          <>
            <ImageGallery
              images={images}
              key={images?.length}
              hideActions={true}
              minImages={3}
              showNSFW={true}
              imageWrapper={imageWrapper}
              dontExpand={true}
            />
            <AddImage
              component={component}
              onClick={() => {
                setSelectedPart("add-image");
                setSelected(true);
              }}
              onClickImage={onClickImage}
              selected={selected && selectedPart === "add-image"}
              menus={IMAGE_MENUS}
              onClickedOption={onClickedImageOption}
            />
          </>
        ) : null}

        <div className={textMenuWrapperClassName}>
          <Menu
            id={"elements." + component.section}
            options={menus}
            onClick={onClickedOption}
          />
          <Editable
            className="text-align-left w-100"
            value={value?.text}
            setValue={(value = "") => onTyped(value)}
            placeholder={placeholder}
            addOnText={addOnText}
            onFocus={() => {
              setSelectedPart("text");
              setSelected(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

export const AddImage = ({
  onClick,
  onClickedOption,
  onClickImage,
  selected,
  menus,
  image,
  component,
  allowUpload = true,
  imageComponent,
  style,
}) => {
  let imageMenuWrapperClassName = "menu-wrapper image-menu-wrapper";
  if (selected) imageMenuWrapperClassName += " selected";
  if (!image?.url) imageMenuWrapperClassName += " hide-preview";
  if (!!image?.url) imageMenuWrapperClassName += " has-image";

  if (image?.url) {
    menus = {
      ...menus,
      download: {
        id: "download",
        label: "Download",
        buttonLabel: (
          <span className="px-2">
            <DownloadOutlined />
          </span>
        ),
      },
      delete: {
        id: "delete",
        label: "Delete",
        buttonLabel: (
          <span className="px-2">
            <DeleteOutlined />
          </span>
        ),
      },
    };
  } else {
    menus = {
      ...menus,
      [IMAGE_MENUS.editImage.id]: undefined,
    };
  }

  return (
    <div className={imageMenuWrapperClassName} onClick={onClick} style={style}>
      <Menu
        id={`elements.${component.section}.image`}
        options={menus}
        onClick={(option) => onClickedOption(option, image)}
      />
      {image?.url ? (
        imageComponent || (
          <GeneratedImage
            image={image}
            imageProps={{ hideActions: true, showNSFW: true, dontExpand: true }}
            background={true}
          />
        )
      ) : (
        <div className="add-image">
          <AddOutlined />
        </div>
      )}

      {allowUpload && (
        <GDDImageAdd
          className="element-add-image"
          selected={selected}
          addImage={(images) => onClickImage({ image: images[0] })}
          content={image}
          loading={false}
          uploadText="Drag & Drop Image"
        />
      )}
    </div>
  );
};

const MultipleItemsContent = ({
  active,
  section,
  value,
  changeGdd,
  hoverText,
  setHoverText,
  component,
  selected,
  setSelected,
  sectionSetActiveComponent,
  gdd,
}) => {
  const { track } = useContext(SocketContext);
  const bus = useBus();
  const ref = useRef();
  const { menu, openMenu, closeMenu } = useContext(GDDContext);

  const [selectedPart, setSelectedPart] = useState();
  const [editImage, setEditImage] = useState();

  useEffect(() => {
    if (active && !value?.elements) {
      addSection();
    }
  }, [active, value]);

  useEffect(() => {
    if (!active) {
      setSelected(false);
      setHoverText();
      setSelectedPart();
    }
  }, [active]);

  const lastEmpty = _.reverse([...(value?.elements || [])]).find(
    (element) => !element.text
  );

  const selectedElement = useMemo(() => {
    if (!selected) return;
    return (value?.elements || []).find(
      (element) => element.id === selected.id
    );
  }, [value, selected]);

  const onClick = useCallback(
    (data) => {
      if (data.url) return onClickImage({ image: data });
      const { text, image, isNew } = data;
      if (text) {
        track("gdd.elements.click-idea", { text });
        if (isNew || !selectedElement) {
          addSection(text, image);
        } else addTextToSelected(text, image);
      }
    },
    [section, value, selectedElement, changeGdd, menu]
  );

  const onClickImage = useCallback(
    ({ image }) => {
      console.log("Clicked image", image);
      if (image) {
        track("gdd.elements.click-image", { image });
        addTextToSelected("", image);
        closeMenu();
        setSelectedPart();
        setSelected();
      }
    },
    [section, value, changeGdd, menu, selectedElement]
  );

  useListener(`${section}.click`, onClick);
  useListener(`${section}.clickImage`, onClickImage);

  useEffect(() => {
    if (!active) {
      if (!!selectedElement && !selectedElement.text) {
        removeElement(selectedElement.id);
      }
      changeSelected();
      setHoverText();
    }
  }, [active, section, menu, selectedElement]);

  function onTyped(element, value) {
    bus.emit(`${section}.typed`, { element, value });
    changeElementText(element, value);
  }

  function addTextToSelected(text, image) {
    if (selectedElement) {
      changeElementText(selectedElement, selectedElement.text + text, image);
    }
  }

  function changeSelected(newValue, selectedPart) {
    setHoverText();
    setSelected(newValue);
    if (selectedPart) setSelectedPart(selectedPart);
    if (selectedElement && selectedElement?.id !== newValue?.id)
      removeIfEmpty(selectedElement, false);
  }

  function changeElementText(chosenElement, text, image) {
    let selectedElement;
    let newValue = {
      ...(value || {}),
      elements: (value?.elements || []).map((element) => {
        if (element.id === chosenElement.id) {
          selectedElement = { ...element, text, image: image || element.image };
          return selectedElement;
        }
        return element;
      }),
    };
    changeGdd(section, newValue, true);
  }

  function addSection(text = "", image) {
    let element;
    if (!!lastEmpty && !!text) {
      changeElementText(lastEmpty, text, image);
    } else {
      element = { id: PerformanceUtils.generateId(), text, image };
      let newValue = {
        ...(value || {}),
        elements: [...(value?.elements || []), element],
      };
      setSelectedPart("text");
      setSelected(element);
      changeGdd(section, newValue, true);
    }
  }

  function removeElement(removeId, change = true) {
    let newValue = {
      ...(value || {}),
      elements: (value?.elements || []).filter(({ id }) => id !== removeId),
    };
    changeGdd(section, newValue, true);
    if (change && selectedElement?.id === removeId) changeSelected();
  }

  function onSelectedElement(element, part) {
    changeSelected(element, part);
  }

  function onClickedOption(option, element) {
    if (option === "delete") {
      let newValue = {
        ...(value || {}),
        elements: (value?.elements || []).filter(({ id }) => element.id !== id),
      };
      changeGdd(section, newValue, true);
    } else {
      let payload = {};
      if (option === MENUS.suggestions.id)
        payload = { clickId: PerformanceUtils.generateId() };
      openMenu({ component, option, section, element, ...payload });
    }
  }

  function onClickedImageOption(option, element, image) {
    if (option === "editImage") {
      setEditImage(image);
    } else if (option === "delete") {
      let newValue = {
        ...(value || {}),
        elements: (value?.elements || []).map((currentElement) => {
          if (element.id === currentElement.id) {
            return { ...element, image: undefined };
          }
          return currentElement;
        }),
      };
      changeGdd(section, newValue, true);
    } else if (option === "download") {
      downloadImage(image);
    } else {
      let payload = {};
      openMenu({ component, option, section, element, ...payload, image });
    }
  }

  function onEditedImages(images) {
    onClickImage({ image: images[0] });
    setEditImage(false);
  }

  let insertedHoverText = false;

  let elements = (value?.elements || []).map((element) => {
    let className = "game-element";
    let isSelected = element.id === selectedElement?.id;
    if (!element.text) className += " empty";

    let isLastEmpty = !!lastEmpty && lastEmpty.id === element.id;

    let addOnImage;
    let addOnText = isSelected || isLastEmpty ? hoverText?.text : undefined;
    if (!!hoverText?.isNew && !isLastEmpty) addOnText = undefined;
    if (insertedHoverText) addOnText = undefined;
    if (!!addOnText) {
      insertedHoverText = true;
      addOnImage = hoverText?.image;
    }

    if (isSelected && !hoverText?.text && !!hoverText?.image) {
      addOnImage = hoverText.image;
    }

    let textMenuOptions = {};

    if (component.followups) {
      textMenuOptions.expand = MENUS.expand;
    }

    textMenuOptions.chat = MENUS.chat;

    if (isLastEmpty) {
      textMenuOptions = {
        suggestions: MENUS.suggestions,
        new: MENUS.new,
        favorites: MENUS.favorites,
      };
    } else {
      textMenuOptions.delete = {
        id: "delete",
        label: "Delete",
        buttonLabel: (
          <span>
            <DeleteOutlined />
          </span>
        ),
      };
    }

    let textMenuWrapperClassName = "menu-wrapper";
    if (isSelected && selectedPart === "text")
      textMenuWrapperClassName += " selected";

    const image = addOnImage || element.image;
    if (addOnImage) className += " hover-image";

    return (
      <div
        key={element.id || "new"}
        className={className}
        ref={ref}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="editable main-element-wrapper">
          {(!!element.text || image?.url) && (
            <AddImage
              component={component}
              onClick={() => {
                onSelectedElement(element, "image");
              }}
              onClickImage={onClickImage}
              selected={isSelected && selectedPart === "image"}
              menus={IMAGE_MENUS}
              onClickedOption={(option) =>
                onClickedImageOption(option, element, image)
              }
              image={image}
            />
          )}

          <div
            className={textMenuWrapperClassName}
            onClick={() => onSelectedElement(element, "text")}
          >
            <Menu
              id={`elements.${component.section}`}
              options={textMenuOptions}
              onClick={(option) => onClickedOption(option, element)}
            />

            <Editable
              className="text-align-left w-100"
              value={element.text}
              setValue={(value = "") => {
                onTyped(element, value);
              }}
              addOnText={addOnText}
              placeholder={`Enter a ${component.section} description`}
              onFocus={() => onSelectedElement(element)}
              autoFocus={isSelected && selectedPart === "text"}
            />
          </div>
        </div>
      </div>
    );
  });

  if (!insertedHoverText && !!hoverText?.text && !!hoverText?.isNew) {
    elements.push(
      <div className="game-element new">
        {hoverText?.image?.url ? (
          <GeneratedImage
            image={hoverText?.image}
            background={true}
            imageProps={{ hideActions: true, dontExpand: true }}
          />
        ) : (
          <div className="add-image">
            <AddOutlined />
          </div>
        )}
        <span>{hoverText?.text}</span>
      </div>
    );
  }

  if (!lastEmpty) {
    elements.push(
      <div
        className="game-element-new d-flex flex-row hide-preview"
        onClick={(event) => {
          event.stopPropagation();
          addSection();
        }}
      >
        <AddCircleOutlined className="font-size-xxl m-0" />
        <span className="text-align-center clickable">
          Add {component.label || component.section}
        </span>
      </div>
    );
  }

  function deselect() {
    setSelected();
    removeIfEmpty(selectedElement);
  }

  function removeIfEmpty(element, change) {
    if (element?.id && !element.image && !element.text) {
      removeElement(element.id, change);
    }
  }

  const masonry = menu
    ? { 100: 1, 1320: 2, 1730: 3 }
    : { 100: 1, 850: 2, 1270: 3 };

  return (
    <>
      {!!editImage && (
        <ImageEditor
          url={editImage?.url}
          initialImage={editImage}
          onClose={() => setEditImage()}
          formValues={{
            image_type: "screenshot",
            platform: gdd.platform,
            genres: gdd.genres,
            perspective: gdd.perspective,
            art_style: gdd.art_style,
          }}
          onResults={onEditedImages}
        />
      )}
      <div
        className="game-elements-content mt-3"
        onClick={(event) => {
          event.stopPropagation();
          deselect();
          sectionSetActiveComponent();
          //closeMenu()
        }}
      >
        <ResponsiveMasonry columnsCountBreakPoints={masonry}>
          <Masonry gutter="5px">{elements}</Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
};

export const GDDGameElementActions = ({
  value,
  component,
  section,
  changeGdd,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const defaultLayout = component?.images_by_default
    ? GAME_ELEMENT_LAYOUTS.images
    : GAME_ELEMENT_LAYOUTS.text;
  const selected = value?.layout || defaultLayout;

  function openContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function changeLayout(layout) {
    setMenuAnchorEl(null);
    changeGdd(section, { ...value, layout }, true);
  }

  if (!component.gameElements) return null;

  return (
    <>
      <div className="action hide-preview" onClick={openContextMenu}>
        <div className="hvr-grow">
          {selected === GAME_ELEMENT_LAYOUTS.images ? (
            <ImageOutlined className="font-size-xxl" />
          ) : (
            <FormatListBulletedOutlined className="font-size-xxl" />
          )}
          <ArrowDropDownOutlined className="font-size-xxl" />
        </div>
      </div>
      <ShowIf condition={!!menuAnchorEl}>
        <MaterialMenu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem
            onClick={() => changeLayout(GAME_ELEMENT_LAYOUTS.images)}
            className={
              selected === GAME_ELEMENT_LAYOUTS.images ? "selected" : ""
            }
          >
            <ListItemIcon>
              <ImageOutlined />
            </ListItemIcon>
            <ListItemText primary="With images" />
          </MenuItem>
          <MenuItem
            onClick={() => changeLayout(GAME_ELEMENT_LAYOUTS.text)}
            className={selected === GAME_ELEMENT_LAYOUTS.text ? "selected" : ""}
          >
            <ListItemIcon>
              <FormatListBulletedOutlined />
            </ListItemIcon>
            <ListItemText primary="Text only" />
          </MenuItem>
        </MaterialMenu>
      </ShowIf>
    </>
  );
};

export default GDDGameElement;
