import React, { useState, useContext, useEffect, useMemo } from "react";
import JSZip from 'jszip';
import "./export-project.scss";
import {
  AutoAwesomeOutlined,
  CheckCircleOutline,
  ChecklistRtlOutlined,
  CodeOutlined,
  DownloadOutlined,
  FileCopyOutlined,
  ReplayOutlined,
} from "@mui/icons-material";
import APIContext from "../../context/APIContext";
import {CircularProgress} from "@material-ui/core";
import {IconPlaceholder} from "./GDD4Menu";
import { useSnackbar } from "notistack";
import {Hint} from "../../scenes/Headquarters";

const getProjectCode = "getProjectCode";
const generateProjectCode = "generateProjectCode";

const ExportProject = ({ projectId, gdd }) => {
  const { call } = useContext(APIContext);
  const {enqueueSnackbar} = useSnackbar();

  const [selectedItem, setSelectedItem] = useState();
  const [items, setItems] = useState(undefined);
  const [loading, setLoading] = useState(false);

  console.log(items);

  useEffect(() => {
    call(getProjectCode, { projectId }).then((response) => {
      if (response.ok) {
        setItems(response.body);
        setSelectedItem(response.body[0]);
      }
    });
  }, [projectId]);

  async function generateAll() {
    setLoading(true);
    setSelectedItem();
    call(generateProjectCode, { projectId }).then((response) => {
      if (response.ok) {
        setLoading(false);
        setItems(response.body);
        setSelectedItem(response.body[0]);
      }
    });
  }

  async function downloadAll() {
    const projectName = gdd.sections[0]?.value?.title

    const zip = new JSZip();
    items.forEach(item => {
      zip.file(`${item.filename}`, item.script);
    });

    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = `${projectName} scripts.zip`
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async function onCopyCode() {
    if (selectedItem && selectedItem.script) {
      try {
        await navigator.clipboard.writeText(selectedItem.script);
        enqueueSnackbar('Code copied to clipboard', { variant: 'success' });
      } catch (err) {
        enqueueSnackbar('Failed to copy code', { variant: 'error' });
      }
    }
  }

  async function onAskLudo() {

  }

  async function onRegenerate() {

  }

  async function onDownloadFile() {
    const content = selectedItem.script;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = selectedItem.filename
    link.click();
    URL.revokeObjectURL(link.href);
  }

  return (
    <div className="export-project">
      {(!items || loading) && <div className="loading-wrapper">
        <CircularProgress size={55}/>
        Please wait...
      </div>}
      {(items && !loading) && (
        <>
          <div className="left-column">
            <div className="action-bar">
              <span onClick={generateAll}>
                <ChecklistRtlOutlined/> Generate All
              </span>
              {items.length > 0 && <span onClick={downloadAll}>
                <DownloadOutlined/> Download All Generated
              </span>}
            </div>
            <div className="items">
              {items.length === 0 && <span>No code</span>}
              {items.map((item) => (
                <div
                  className={
                    "item" + (selectedItem?.name === item.name ? " active" : "")
                  }
                  onClick={() => setSelectedItem(item)}
                >
                  <CodeOutlined/> {item.name}{" "}
                  <Hint
                    hint={item.explanation + "\n\n" + item.implementation_details}
                    iconClassName="help"
                  />
                  {item.script && <CheckCircleOutline/>}
                </div>
              ))}
            </div>
          </div>
          <div className="right-column">
            {selectedItem && (
              <>
                <div className="action-bar">
                  <span onClick={onCopyCode}>
                    <FileCopyOutlined/> Copy Code
                  </span>
                  <span onClick={onAskLudo}>
                    <AutoAwesomeOutlined/> Ask Ludo
                  </span>
                  <span onClick={onRegenerate}>
                    <ReplayOutlined/> Re-generate
                  </span>
                  <span onClick={onDownloadFile}>
                    <DownloadOutlined/> Download
                  </span>
                </div>
                <pre className="script">{selectedItem.script}</pre>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ExportProjectHeader = ({gdd}) => {
  const projectName = gdd.sections[0]?.value?.title;

  return (
    <div className="export-project-header">
      <div className="icon">
        <IconPlaceholder icon={gdd.icon}/>
      </div>
      <div className="description gradient-text2">
        Generate and Export <b>Unity Code</b> to accelerate{" "}
        <i>
          <b>{projectName}</b>
        </i>
        's development!
      </div>
    </div>
  );
};

export default ExportProject;
