import React, {useContext, useEffect, useState} from 'react';
import CacheContext from "context/CacheContext";

export default function useWatchWidth(ref) {

  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;
  const sidePanelOpen = !!detailsPanel.mode;

  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      handleResize();
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [ref.current, sidePanelOpen])

  function handleResize() {
    let width = ref.current ? ref.current.offsetWidth : 0;
    setWidth(width);
  }

  return width;
}
