import React, { useState, useEffect, useRef, useContext } from "react";
import APIContext from "../../context/APIContext";
import {
  convertLudoScore,
  getLudoScoreDescription,
} from "../../components/common/GeneratedGameCard";
import DetailsPanelContext from "../../context/DetailsPanelContext";
import SocketContext from "../../context/SocketContext";
import CacheContext from "../../context/CacheContext";
import { AutoAwesomeOutlined, Sync, TryOutlined } from "@mui/icons-material";
import { CircularProgress } from "@material-ui/core";

const getLudoScoreGdd = "getLudoScoreGdd";

const GDDLudoScore = ({
  active,
  loading,
  scrollTo,
  changeGdd,
  section,
  value,
  projectId,
}) => {
  const [selected, setSelected] = useState();
  const { call } = useContext(APIContext);
  const { showCompetitiveAnalysis } = useContext(DetailsPanelContext);

  const ref = useRef();
  useEffect(() => {
    if (active && scrollTo && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (!active) setSelected();
  }, [active, scrollTo]);

  const actionsActive = loading ? false : active;
  const noScore = value?.score?.score === undefined;

  let className = "section ludo-score";
  if (actionsActive) className += " active";
  if (noScore) className += " empty";

  function changeScore(score) {
    changeGdd(section, { ...(value || {}), score }, true);
  }

  useEffect(() => {
    if (noScore) {
      call(getLudoScoreGdd, { id: projectId }).then((response) => {
        if (response.ok) {
          changeScore(response.body);
        }
      });
    }
  }, [noScore, projectId]);

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";
  let textClassName = "menu-wrapper editable";
  if (selected === "text") textClassName += " selected";

  function onClickMore(metric) {
    showCompetitiveAnalysis({
      game_ids: metric.supporting_games,
    });
  }

  return (
    <div className={className} ref={ref}>
      <div className="left-content">
        <span className="section-subtitle">Ludo Score</span>
      </div>
      <div className="right-content">
        {!noScore && (
          <div className="ludo-score-wrapper">
            <span className="score-line">
              <span className="score-number">
                {convertLudoScore(value.score.score)}
              </span>
              {value.score.badge?.icon_url && (
                <>
                  <img src={value.score.badge.icon_url} alt="" />
                  <span>
                    <b>{value.score.badge.name}</b>:{" "}
                    {value.score.badge.description}
                  </span>
                </>
              )}
            </span>
            {getLudoScoreDescription(value.score, true, onClickMore)}
          </div>
        )}
      </div>
    </div>
  );
};

export const GDDLudoScoreActions = ({
  component,
  section,
  value,
  changeGdd,
  projectId,
}) => {
  const { track } = useContext(SocketContext);
  const { cache } = useContext(CacheContext);
  const { call, loading } = useContext(APIContext);
  const { gddComponents } = cache;

  if (component?.section !== gddComponents.score.section) return null;
  const isLoading = loading[getLudoScoreGdd];

  function changeScore(score) {
    changeGdd(section, { ...(value || {}), score }, true);
  }

  async function onCalculateScore() {
    if (!isLoading) {
      track("gdd.score.click-calculate");
      call(getLudoScoreGdd, { id: projectId }).then((response) => {
        if (response.ok) {
          changeScore(response.body);
        }
      });
    }
  }

  return (
    <>
      <div
        className="action score-icon hide-preview mr-3"
        onClick={onCalculateScore}
      >
        <div className="hvr-grow icon-wrapper">
          {isLoading ? (
            <CircularProgress className="text-white mx-auto" size={23} />
          ) : (
            <>
              <AutoAwesomeOutlined className="font-size-xxl" />
              <span>Calculate Ludo Score</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GDDLudoScore;
