import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  MenuItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Menu,
  Chip,
  Select,
  CircularProgress,
} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import ContentEditable from "react-contenteditable";
import APIContext from "context/APIContext";
import MyButton, {
  ConfirmDialog,
  LockIcon,
} from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import Linkify from "react-linkify";
import PerformanceUtils from "helpers/PerformanceUtils";
import SocketContext from "context/SocketContext";
import _ from "lodash";
import AuthContext from "context/AuthContext";
import { Menu as GDDHoverMenu } from "pages/GDD3/GDDGameSummary";
import {
  AddOutlined,
  ArrowDropDown,
  CloseOutlined,
  Delete,
  DeleteOutline,
  Download,
  FileUploadOutlined,
  NotesOutlined,
  PeopleAltOutlined,
  PersonOutline,
  SportsEsportsOutlined,
} from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
import Team from "pages/Team";
import { convertProxyUrl } from "components/common/ImageGallery";
import FileUpload, { toBase64 } from "components/Controls/FileUpload";
import { useHistory } from "react-router";
import { ImportConcept } from "../../scenes/Headquarters2";

const DEFAULT_OBJECT = {};

export const FAIcon = ({
  onClick,
  className = "",
  icon,
  tooltip,
  tooltipClassname = "",
  animation = "fadeIn",
}) => (
  <Tooltip
    arrow
    placement="top"
    title={tooltip}
    className={tooltipClassname}
    disableHoverListener={!tooltip}
    PopperProps={{
      className:
        "MuiTooltip-popper MuiTooltip-popperArrow secondary " +
        tooltipClassname,
    }}
  >
    <span
      className={`fa-icon animate__animated animate__${animation} ` + className}
      onClick={onClick}
    >
      {icon}
    </span>
  </Tooltip>
);

export const Editable = ({
  value = "",
  setValue,
  placeholder,
  className = "",
  showPreview = true,
  addOnText,
  style = {},
  onKeyPress,
  onFocus,
  onBlur,
  autoFocus = false,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && autoFocus) ref.current.focus();
  }, [ref, autoFocus]);

  const onKeyDown = useCallback(
    (event) => {
      if (!!onKeyPress) {
        onKeyPress(event);
      } else if (event.key === "Enter") {
        document.execCommand("insertLineBreak");
        event.preventDefault();
      }
    },
    [value]
  );

  function setValueWrapper(text) {
    let cleaned = cleanText(text);
    return setValue(cleaned);
  }

  function onClickWrapper() {
    ref.current.focus();
    if (onFocus) onFocus();
  }

  function cleanText(text) {
    var tmp = document.createElement("div");
    text = text.replace(/&/g, "&amp;"); // Escape ampersands
    text = text.replace(/&nbsp;/g, " ");
    text = text.replace(/<\/p>/g, "\n");
    text = text.replace(/<br>/g, "\n");
    text = text.replace(/\\n/g, "<br />\n");

    tmp.innerHTML = text;
    let innerText = tmp.innerText;

    innerText = innerText.replace(/</g, "&lt;");
    innerText = innerText.replace(/>/g, "&gt;");

    return innerText;
  }

  //The ContentEditable element screws up the PDF export on Safari, so we have to
  //replace it with something that works well and looks exactly the same
  const readOnlyString = useMemo(() => {
    let val = value.split("&nbsp;").join(" ");
    let lines = val.replace(/<br\s*[\/]?>/gi, "\n").split("\n");
    let result = [];
    lines.forEach((line, index) => {
      result.push(line);
      if (index !== lines.length - 1) result.push(<br />);
    });
    return result;
  }, [value]);

  const html = !!addOnText
    ? `<div><span>${value}</span><span class="opacity-8">${addOnText}</span></div>`
    : value;

  return (
    <div
      className="content-editable-wrapper"
      style={style}
      key={!!onKeyPress}
      onClick={onClickWrapper}
    >
      {showPreview && (
        <div className={className + " pre show-preview"}>
          <Linkify>{readOnlyString}</Linkify>
        </div>
      )}
      <ContentEditable
        className={className + " pre hide-preview"}
        innerRef={ref}
        html={html}
        disabled={!!addOnText}
        onChange={
          !!setValue
            ? (event) => setValueWrapper(event.target.value)
            : undefined
        }
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClickWrapper}
      />
    </div>
  );
};

export const GDDModal = ({
  open,
  onClose,
  title,
  children,
  className = "",
  closeRight = true
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={"gdd-dialog ludo-modal " + className}
    >
      {onClose && <span className={closeRight ? "top-right" : "top-left"}>
        <IconButton onClick={onClose}>
          <CloseOutlined className="font-size-xxl pointer text-secondary" />
        </IconButton>
      </span>}
      <ShowIf condition={!!title}>
        <DialogTitle className="text-align-center text-primary">
          {title}
        </DialogTitle>
      </ShowIf>
      {children}
    </Dialog>
  );
};

const addProject = "addProject";
const updateUserInfo = "updateUserInfo";

export const GDDMenu = ({
  sticky = true,
  sidePanelOpen,
  numberAllowedProjects,
}) => {
  const { track } = useContext(SocketContext);
  const { call } = useContext(APIContext);
  const { setCache, cache } = useContext(CacheContext);
  const { auth } = useContext(AuthContext);
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [mainWidth, setMainWidth] = useState(0);
  const [moreConceptsAnchor, setMoreConceptsAnchor] = useState(null);
  const [conceptAnchor, setConceptAnchor] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [importConcept, setImportConcept] = useState(false);
  const { projects = DEFAULT_OBJECT, selectedProjectId } = cache;

  const mainRef = useRef();

  useEffect(() => {
    if (mainRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [mainRef.current, sidePanelOpen]);

  function handleResize() {
    setMainWidth(mainRef.current ? mainRef.current.offsetWidth : 0);
  }

  let className = "top-bar";
  if (sticky) className += " sticky-top";

  const conceptWidthWithImage = 200;
  const conceptWidthWithoutImage = conceptWidthWithImage - 40;

  const { barProjects, dropdownProjects } = useMemo(() => {
    let number = 0;
    let totalWidth = 0;
    let orderedProjects = _.reverse(_.sortBy(projects, ["date"]));
    let convertedProjets = orderedProjects.map(({ gdd2, _id, user_id }) => {
      if (totalWidth <= mainWidth - 230) number++;
      totalWidth += gdd2?.icon
        ? conceptWidthWithImage
        : conceptWidthWithoutImage;
      let summary = (gdd2?.sections || []).find(
        ({ name }) => name === "summary"
      );
      return {
        value: _id,
        label: summary?.value?.title || "Untitled",
        icon: gdd2?.icon,
        user_id,
      };
    });

    let barProjects = convertedProjets.slice(0, number);
    let dropdownProjects = convertedProjets.slice(number);

    return { barProjects, dropdownProjects };
  }, [projects, mainWidth]);

  async function onSelectedProject(event, projectId) {
    track("gdd.change-concept", {});
    event.preventDefault();
    event.stopPropagation();
    history.push(`/game-concept/${projectId}`);
    setMoreConceptsAnchor(null);
  }

  async function addProject() {
    track("gdd.new-project");
    let project = await addNewProject(
      projects,
      call,
      setCache,
      defaultPlatform
    );
    if (project) {
      let path = {
        pathname: "/game-concept/" + project._id,
      };
      history.push(path);
    }
  }

  const defaultPlatform = auth.user.platform;

  return (
    <div className={className} ref={mainRef}>
      <DeleteModal id={deleteModal} onClose={() => setDeleteModal(false)} />
      <div className="concept-bar">
        <div className="new-concept">
          <div className="button-wrapper">
            <IconButton
              onClick={(event) => setMenuAnchorEl(event.currentTarget)}
              id="gdd.new"
              name="Start New Concept"
            >
              <AddOutlined className="font-size-xl pointer text-white" />
            </IconButton>
          </div>
          {menuAnchorEl && (
            <Menu
              anchorEl={menuAnchorEl}
              keepMounted
              open={!!menuAnchorEl}
              onClose={() => setMenuAnchorEl(null)}
            >
              <MenuItem onClick={addProject}>
                <ListItemIcon>
                  <AddOutlined />
                </ListItemIcon>
                <ListItemText primary="Create New Game Concept" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  track("gdd.import");
                  setImportConcept(true);
                  setMenuAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <FileUploadOutlined />
                </ListItemIcon>
                <ListItemText primary="Import Existing Concept" />
              </MenuItem>
            </Menu>
          )}
          {importConcept && (
            <ImportConcept onClose={() => setImportConcept(false)} />
          )}
          <div className="concepts">
            {barProjects.map((project, index) => {
              let className = "concept";
              if (project.value === selectedProjectId) className += " active";
              let locked = index >= numberAllowedProjects;

              let label = project.label;
              const MAX = 14;
              if (label.length > MAX) {
                label = label.slice(0, MAX - 4 - 3) + "..." + label.slice(-4);
              }

              const isOwnProject = project.user_id === auth.user._id;

              return (
                <div
                  key={project.value}
                  className={className}
                  onClick={(event) => onSelectedProject(event, project.value)}
                >
                  {project.icon?.url ? (
                    <img className="icon" src={convertProxyUrl(project.icon)} />
                  ) : null}
                  <span className="text-truncate d-block">{label}</span>
                  <ShowIf condition={locked}>
                    <LockIcon />
                  </ShowIf>
                  {isOwnProject && (
                    <IconButton
                      onClick={(event) => {
                        setConceptAnchor({
                          id: project.value,
                          anchor: event.currentTarget,
                        });
                      }}
                      id="gdd.concept-more"
                    >
                      <ArrowDropDown
                        className="font-size-xxxl"
                        style={{ marginTop: "5px" }}
                      />
                    </IconButton>
                  )}
                </div>
              );
            })}
          </div>
          <ShowIf condition={dropdownProjects.length > 0}>
            <div className="more-concepts">
              <IconButton
                onClick={(event) => setMoreConceptsAnchor(event.currentTarget)}
                id="gdd.more"
                name="All Concepts"
              >
                <ArrowDropDown
                  className="font-size-xxxl"
                  style={{ marginTop: "5px" }}
                />
              </IconButton>
              <Menu
                anchorEl={moreConceptsAnchor}
                keepMounted
                open={!!moreConceptsAnchor}
                onClose={() => setMoreConceptsAnchor()}
              >
                {dropdownProjects.map((project) => {
                  return (
                    <MenuItem
                      key={project.value}
                      onClick={(event) =>
                        onSelectedProject(event, project.value)
                      }
                    >
                      {project.icon?.url ? (
                        <ListItemIcon>
                          <div
                            style={{
                              height: "25px",
                              width: "25px",
                              overflow: "hidden",
                              display: "flex",
                              placeContent: "center",
                              borderRadius: "5px",
                            }}
                          >
                            <img
                              className="icon"
                              style={{ height: "25px" }}
                              src={convertProxyUrl(project.icon)}
                            />
                          </div>
                        </ListItemIcon>
                      ) : null}
                      <ListItemText primary={project.label} />
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </ShowIf>
        </div>
      </div>
      <Menu
        anchorEl={conceptAnchor?.anchor}
        open={!!conceptAnchor?.id}
        onClose={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setConceptAnchor();
        }}
      >
        <MenuItem
          className="text-red"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setDeleteModal(conceptAnchor.id);
            setConceptAnchor();
          }}
        >
          <ListItemIcon>
            <DeleteOutline className="font-size-xxxl" />
          </ListItemIcon>
          <ListItemText primary="Delete Project" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export const DeleteModal = ({ id, onClose, forwardRef }) => {
  const { call } = useContext(APIContext);
  const { setCache, cache, setCacheValue } = useContext(CacheContext);

  async function onDeleteProject(id) {
    let response = await call("archiveProject", { projectId: id });
    if (response.ok) {
      let projects = PerformanceUtils.removeFromArray(
        response.body,
        cache.projects,
        "_id"
      );
      let selectedId =
        id === cache.selectedProjectId
          ? (projects[0] || {})._id
          : cache.selectedProjectId;
      setCache((prevState) => {
        return {
          ...prevState,
          projects,
          selectedProjectId: selectedId,
        };
      });
      setTimeout(() => {
        setCacheValue("selectedProjectId", selectedId);
      }, 0);
      call(updateUserInfo, { data: { selected_project: selectedId } });
    }
    onClose();
  }

  return (
    <ConfirmDialog
      forwardRef={forwardRef}
      text="Are you sure you want to delete this project?"
      textElement={
        <span>
          Are you sure you want to <b>get rid</b> of this Game Concept{" "}
          <b>forever</b>?
        </span>
      }
      onCancel={() => onClose()}
      onConfirm={() => onDeleteProject(id)}
      open={!!id}
      confirmLabel="Delete"
    />
  );
};

const DEFAULT_ARRAY = [];

export const ShareTooltip = ({ selectedProjectId }) => {
  const { auth } = useContext(AuthContext);
  const { cache, setCache } = useContext(CacheContext);
  const { call, loading } = useContext(APIContext);
  const { projects = DEFAULT_ARRAY } = cache;
  const [openTeam, setOpenTeam] = useState(false);

  const project = useMemo(() => {
    return projects.find(({ _id }) => _id === selectedProjectId);
  }, [selectedProjectId, projects]);

  async function onToggleShared() {
    if (loading[addProject]) return;
    let newProject = { ...project };
    let teamId = (auth.user.teams || [])[0];
    newProject.team_id = shared ? undefined : teamId;
    let response = await call(addProject, { project: newProject });
    if (response.ok) {
      setCache((prevState) => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(
            response.body,
            prevState.projects,
            "_id"
          ),
        };
      });
    }
  }

  const shared = !!project.team_id;

  const isOwnProject = project.user_id === auth.user._id;

  return (
    <>
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={openTeam}
        onClose={() => setOpenTeam(false)}
        className="ludo-modal"
        classes={{
          paper: "modal-content rounded border-0 bg-white p-3 p-xl-0",
        }}
        style={{ minWidth: "90%", zIndex: 1501 }}
      >
        <div className="bg-light">
          <Team onClose={() => setOpenTeam(false)} />
        </div>
      </Dialog>
      <ShowIf condition={!shared}>
        <div className="d-flex">
          <span className="font-size-sm">This concept is private.</span>
        </div>
        <div className="d-flex">
          <MyButton
            className="blue"
            style={{ width: "280px" }}
            onClick={onToggleShared}
          >
            <PeopleAltOutlined className="text-white mr-2" />
            Share with Your Team
          </MyButton>
        </div>
        <div className="d-flex pt-4">
          <span
            className="font-size-sm font-italic text-secondary pointer"
            onClick={() => setOpenTeam(true)}
          >
            Who is in my team?
          </span>
        </div>
      </ShowIf>
      <ShowIf condition={shared && isOwnProject}>
        <div className="d-flex">
          <span className="font-size-sm">
            This concept is shared with&nbsp;
          </span>
          <span
            className="font-size-sm font-italic text-secondary underline pointer"
            onClick={() => setOpenTeam(true)}
          >
            your team.
          </span>
        </div>
        <div className="d-flex">
          <MyButton
            className="blue"
            style={{ width: "280px" }}
            onClick={onToggleShared}
          >
            <PersonOutline className="text-white mr-2" />
            Make Private
          </MyButton>
        </div>
      </ShowIf>
      <ShowIf condition={shared && !isOwnProject}>
        <div className="d-flex">
          <span className="font-size-sm">
            This concept has been shared with you
          </span>
        </div>
      </ShowIf>
    </>
  );
};

export const ExportPDFButton = ({ onClick }) => {
  return (
    <div className="action-chip hvr-grow" onClick={onClick}>
      <span>PDF</span>
      <Download className="font-size-xxxxxxl d-block" />
    </div>
  );
};

export const ExportTextButton = ({ onClick }) => {
  return (
    <div className="action-chip hvr-grow" onClick={onClick}>
      <span>Text</span>
      <NotesOutlined className="font-size-xxxxxxl d-block" />
    </div>
  );
};

export async function addNewProject(projects, call, setCache, platform, gdd2) {
  let name = "Untitled";

  let found = true;
  let index = 0;
  while (found) {
    found = !!projects.find((project) => project.name === name);
    if (found) {
      name = `Untitled (${++index})`;
    }
  }
  let response = await call(addProject, { project: { name, platform, gdd2 } });
  if (response.ok) {
    setCache((prevState) => {
      return {
        ...prevState,
        projects: PerformanceUtils.editOrAdd(
          response.body,
          prevState.projects,
          "_id"
        ),
        selectedProjectId: response.body._id,
      };
    });
    call(updateUserInfo, { data: { selected_project: response.body._id } });
    return response.body;
  }
}

export function getNumberAllowedProjects(auth) {
  const metadataProjects = auth.subscription?.plan?.metadata?.projects;
  return metadataProjects === "Unlimited"
    ? Number.MAX_VALUE
    : parseInt(metadataProjects);
}

export function isProjectEditable(projects, projectId, numberAllowedProjects) {
  let orderedProjects = _.reverse(_.sortBy(projects, ["date"]));
  let foundIndex = 0;
  orderedProjects.forEach(({ _id }, index) => {
    if (_id === projectId) foundIndex = index;
  });
  return foundIndex < numberAllowedProjects;
}

export const WhiteHtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "black",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
    padding: "15px",
    borderRadius: "15px",
  },
}))(Tooltip);

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#131648",
    color: "white",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
    padding: "15px",
    borderRadius: "15px",
  },
}))(Tooltip);

export const GenresSelection = ({
  project,
  gdd,
  changeGdd,
  changeAllGdd,
  preview,
}) => {
  let { genre = "" } = gdd;

  const { call } = useContext(APIContext);
  const { auth } = useContext(AuthContext);
  const { track } = useContext(SocketContext);
  const { setCache } = useContext(CacheContext);

  useEffect(() => {
    if (gdd.genres === undefined) {
      changeGdd("genres", auth.user.genres);
    }
  }, [gdd.genres]);

  if (genre === "<br>") genre = "";
  if (genre.trim().length === 0) genre = "";

  let placeholderGenre = !genre;
  let genreClassname = "genre";
  if (placeholderGenre) genreClassname += " placeholder";

  function deleteGenre(genre) {
    track("gdd.delete-gdd", { genre });
    let newGenres = (gdd.genres || []).filter((g) => g !== genre);
    changeGdd("genres", newGenres);
  }

  function addGenre(genre) {
    track("gdd.add-genre", { genre });
    let newGenres = _.uniq([...(gdd.genres || []), genre]);
    changeGdd("genres", newGenres);
  }

  async function changePlatform(platform) {
    let projectData = { ...project, platform };
    projectData.gdd2.platform = platform;
    let response = await call(addProject, { project: projectData });
    if (response.ok) {
      setCache((prevState) => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(
            response.body,
            prevState.projects,
            "_id"
          ),
        };
      });
    }
  }

  async function changeArtStyle(style) {
    track("gdd.change-art-style", { art_style: style });
    changeAllGdd({ ...gdd, art_style: style });
  }

  async function changePerspective(perspective) {
    track("gdd.change-perspective", { perspective });
    changeAllGdd({ ...gdd, perspective });
  }

  return (
    <div className="genres-wrapper">
      <div className={genreClassname}>
        <div className="platform-selection">
          <AddPlatform
            selected={project.platform}
            onSelect={(platform) => changePlatform(platform)}
          />
        </div>
        <div className="platform-selection">
          <AddArtStyle
            selected={project.gdd2.art_style}
            onSelect={(style) => changeArtStyle(style)}
          />
        </div>
        <div className="platform-selection">
          <AddPerspective
            selected={project.gdd2.perspective}
            onSelect={(platform) => changePerspective(platform)}
          />
        </div>
        <MultipleGenres
          selectedGenres={gdd.genres || []}
          onDelete={preview ? undefined : deleteGenre}
          onAdd={preview ? undefined : addGenre}
          size="large"
        />
      </div>
    </div>
  );
};

export const MultipleGenres = ({
  selectedGenres = DEFAULT_ARRAY,
  onAdd,
  onDelete,
  size = "small",
}) => {
  return (
    <div className="multiple-genres-input">
      {selectedGenres.map((genre) => {
        return (
          <Chip
            className="mt-1 mr-1 w-fit pl-3 pr-3"
            size={size}
            key={genre}
            label={genre}
            onDelete={!!onDelete ? () => onDelete(genre) : undefined}
          />
        );
      })}
      <ShowIf condition={!!onAdd}>
        <AddGenre selectedGenres={selectedGenres} onSelect={onAdd} />
      </ShowIf>
    </div>
  );
};

export const AddPlatform = ({ selected = "Mobile", onSelect }) => {
  const { cache } = useContext(CacheContext);
  const { platforms = DEFAULT_ARRAY } = cache;

  return (
    <div className="add-icon-wrapper">
      <div className="add-icon">
        <Chip className="mt-1 mr-1 w-fit px-2" label={selected} />
      </div>
      <Select
        name="platform"
        label="Select Platform"
        className="add-platform"
        onChange={(event) => {
          onSelect(event.target.value);
        }}
        value=""
      >
        {platforms.map((platform) => {
          return (
            <MenuItem key={platform} value={platform}>
              {platform}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export const AddGenre = ({ selectedGenres = DEFAULT_ARRAY, onSelect }) => {
  const { cache } = useContext(CacheContext);
  const { genres = DEFAULT_ARRAY } = cache;

  const displayGenres = useMemo(() => {
    return genres.filter((g) => !selectedGenres.includes(g));
  }, [selectedGenres, genres]);

  return (
    <div className="add-icon-wrapper hide-preview">
      <div className="add-icon">
        <Chip className="mt-1 mr-1 w-fit px-2" size="small" label="+" />
      </div>
      <Select
        name="genre"
        label="Add Genre"
        className="add-genre"
        value=""
        onChange={(event) => {
          onSelect(event.target.value);
        }}
      >
        {displayGenres.map((genre) => {
          return (
            <MenuItem key={genre} value={genre}>
              {genre}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export const AddArtStyle = ({ selected = "Any style", onSelect }) => {
  const { cache } = useContext(CacheContext);
  const { generationStyles = DEFAULT_ARRAY } = cache;

  return (
    <div className="add-icon-wrapper">
      <div className="add-icon">
        <Chip className="mt-1 mr-1 w-fit px-2" label={selected} />
      </div>
      <Select
        name="art_style"
        label="Art Style"
        className="add-platform"
        onChange={(event) => {
          onSelect(event.target.value);
        }}
        value=""
      >
        {generationStyles.map((style) => {
          return (
            <MenuItem key={style} value={style}>
              {style}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export const AddPerspective = ({ selected = "Any perspective", onSelect }) => {
  const { cache } = useContext(CacheContext);
  const { perspectives = DEFAULT_ARRAY } = cache;

  return (
    <div className="add-icon-wrapper">
      <div className="add-icon">
        <Chip className="mt-1 mr-1 w-fit px-2" label={selected} />
      </div>
      <Select
        name="perspective"
        label="Perspective"
        className="add-platform"
        onChange={(event) => {
          onSelect(event.target.value);
        }}
        value=""
      >
        {perspectives.map((style) => {
          return (
            <MenuItem key={style} value={style}>
              {style}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export const GDDImageAdd = ({
  className,
  selected,
  menus,
  setSelected,
  onClickedOption,
  addImage,
  section,
  menu,
  loading,
  uploadText,
  content,
  ...props
}) => {
  if (menu?.component?.section === section) className += " open";

  return (
    <div className={className} onClick={setSelected}>
      {loading ? null : (
        <GDDHoverMenu options={menus} onClick={onClickedOption} />
      )}
      {props.children}
      {loading ? (
        <div className="loading-wrapper">
          <CircularProgress size={45} />
        </div>
      ) : (
        <UploadImage addImages={addImage} uploadText={uploadText} />
      )}
    </div>
  );
};

const uploadImage = "uploadImage";

export const UploadImage = ({
  addImages,
  uploadText,
  hideFiles = true,
  title = "",
}) => {
  const { call } = useContext(APIContext);

  async function onFilesUpdated(files) {
    if (files.length > 0) {
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        addImages(response.body);
      }
    }
  }

  return (
    <FileUpload
      hideFiles={hideFiles}
      title={title}
      onFilesUpdated={onFilesUpdated}
      uploadText={uploadText}
    />
  );
};
