import React, { useContext, useEffect, useMemo, useState } from "react";
import "./style.scss";
import StepByStepGenerator from "../../pages/StepByStepGenerator";
import {
  GenreSelection,
  PlatformSelection,
} from "../../components/layout-components/SidebarMenu";
import { Hint } from "../Headquarters";
import logoText from "assets/images/logo-text-white.png";
import AuthContext from "../../context/AuthContext";
import SocketContext from "../../context/SocketContext";
import CacheContext from "../../context/CacheContext";
import MyButton, { GeneratingButton } from "../../components/Controls/MyButton";
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  AssessmentOutlined,
  BurstModeOutlined,
  ChatBubbleOutline,
  EmojiEventsOutlined,
  FileCopyOutlined,
  FileUploadOutlined, NotesOutlined,
  PictureAsPdfOutlined,
  PlayArrowOutlined,
  SearchOutlined,
  TrendingUpOutlined,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import { Avatar, TextField, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import featureAssistantImage from "assets/images/misc/feature-assistant.png";
import featureTrendsImage from "assets/images/misc/feature-trends.png";
import featureConceptImage from "assets/images/misc/feature-concept.png";
import featureIdeatorImage from "assets/images/misc/feature-ideator.png";
import featureMarketAnalysisImage from "assets/images/misc/feature-market-analysis.png";
import featureSearchImage from "assets/images/misc/feature-search.png";
import featureImageGeneratorImage from "assets/images/misc/feature-image-generator.png";
import featureTopChartsImage from "assets/images/misc/feature-top-charts.png";
import {
  AddArtStyle,
  AddPerspective,
  AddPlatform,
  GDDModal,
  MultipleGenres,
  UploadImage,
} from "../../pages/GDD3/Helpers";
import PerformanceUtils from "../../helpers/PerformanceUtils";
import { NewGDDModal } from "../../components/common/GeneratedGameCard";
import usePersistedState from "../../hooks/usePersistedState";
import backgroundImage from "../../assets/images/background/step-bg.webp";
import FileUpload from "../../components/Controls/FileUpload";
import APIContext from "../../context/APIContext";
import { FormTab, FormTabs } from "../../components/common/FormTabs";

const Headquarters2 = ({}) => {
  const history = useHistory();
  const { track } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { cache } = useContext(CacheContext);
  const { credits, projects, selectedProjectId } = cache;
  const [importConcept, setImportConcept] = useState(false);

  const project = useMemo(
    () => projects?.find(({ _id }) => _id === selectedProjectId),
    [projects, selectedProjectId]
  );

  const [showResults, setShowResults] = usePersistedState(
    "HQ.showResults",
    false,
    true
  );
  const [resultsLength, setResultsLength] = usePersistedState(
    "HQ.resultsLength",
    0,
    true
  );
  const [intro, setIntro] = usePersistedState("HQ.intro", true, true);

  let headerFormWrapperClassName = "header-form-wrapper d-flex flex-column";
  if (credits.max) headerFormWrapperClassName += " short";

  const shouldShowResults = showResults && resultsLength > 0;

  const welcomeText = useMemo(() => {
    let name = auth?.user?.first_name ? ` ${auth?.user?.first_name}` : "";
    let text = `Hi${name}, Welcome to Ludo.ai!`;
    if (shouldShowResults)
      text = "Here are your generated game ideas!";
    return text;
  }, [auth, shouldShowResults]);

  const projectName = project?.gdd2?.sections?.[0]?.value?.title || "";
  const projectIcon = project?.gdd2?.icon?.url;
  const hasActiveProject = !!project && !projectName?.includes("Untitled");

  return (
    <div className="headquarters2 w-100">
      <div className="header">
        <div className="top-bg-content">
          <div className={headerFormWrapperClassName}>
            <div className="hq-form-wrapper">
              <img alt="Ludo" className="d-block m-auto logo" src={logoText} />
              <div className="form">
                <div className="d-flex flex-row input-fields">
                  <div className="info-text">
                    <span className="text">
                      Tweaking these settings will shape your Ludo journey
                    </span>
                    <Hint
                      hint="These will be your new defaults for all Ludo tools"
                      iconClassName="help text-white-important"
                      placement="bottom"
                    />
                  </div>
                  <GenreSelection />
                  <PlatformSelection chips={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-hq-content">
        <div className="main-content">
          <span className="welcome">{welcomeText}</span>
          {showResults ? (
            <MyButton
              key="back-from-results"
              id="hq.back-from-results"
              style={{ width: "150px" }}
              className="clear round white m-0"
              onClick={() => setShowResults(false)}
            >
              <ArrowBackOutlined className="mr-2 font-size-xxxl" /> Back
            </MyButton>
          ) : (
            resultsLength > 0 && (
              <MyButton
                key="see-results"
                id="hq.see-results"
                style={{ width: "200px" }}
                className="clear round white m-0"
                onClick={() => setShowResults(true)}
              >
                See {resultsLength} Results{" "}
                <ArrowForwardOutlined className="ml-2 font-size-xxxl" />
              </MyButton>
            )
          )}
          {(!intro || shouldShowResults) && (
            <StepByStepGenerator
              includeTitle={false}
              standaloneResults={true}
              showResults={showResults}
              setShowResults={setShowResults}
              allowClear={false}
              setResultsLength={setResultsLength}
              formKey="StepByStepHQ"
              onReset={() => {
                setIntro(true)
                setResultsLength(0);
              }}
            />
          )}
          {!shouldShowResults && (
            <div className="padded-content">
              <div className="top-row">
                {intro && (
                  <ThemedBox
                    line1="Starting from scratch?"
                    line2="Create a new"
                    line3="Game Concept"
                    style={{ border: "2px solid white" }}
                    bg={backgroundImage}
                    actions={
                      <>
                        <div />
                        <MyButton
                          id="step-generator.start"
                          className="round text-gradient"
                          onClick={() => {
                            track("step-by-step.start");
                            setIntro(false);
                          }}
                        >
                          <PlayArrowOutlined /> Start
                        </MyButton>
                      </>
                    }
                  />
                )}
                <ThemedBox
                  line1="Got an Existing Project?"
                  line2={hasActiveProject ? "Continue or Import" : "Import"}
                  line3="Game Design"
                  bg={project?.gdd2?.header?.url}
                  actions={
                    <>
                      {hasActiveProject ? (
                        <MyButton
                          id="hq.continue"
                          className="round clear"
                          onClick={() => {
                            history.push(`/game-concept/${selectedProjectId}`);
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="top"
                            title={`Your "${projectName}" concept`}
                            PopperProps={{
                              disablePortal: true,
                              className:
                                "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {projectIcon ? (
                                <Avatar src={projectIcon} />
                              ) : (
                                <FileCopyOutlined />
                              )}{" "}
                              Continue
                            </div>
                          </Tooltip>
                        </MyButton>
                      ) : (
                        <div />
                      )}
                      <MyButton
                        id="hq.import"
                        className="round clear"
                        onClick={() => setImportConcept(true)}
                      >
                        <FileUploadOutlined /> Import
                      </MyButton>
                      {importConcept && (
                        <ImportConcept
                          onClose={() => setImportConcept(false)}
                        />
                      )}
                    </>
                  }
                />
              </div>
              <span className="smaller-title">
                These tools might also interest you
              </span>
              <div className="row features">
                <Feature
                  url="/trending"
                  image={featureTrendsImage}
                  icon={<TrendingUpOutlined />}
                  title="Daily Trends"
                  id="trends"
                />
                <Feature
                  url="/assistant"
                  image={featureAssistantImage}
                  icon={<ChatBubbleOutline />}
                  title="Chat Assistant"
                  id="assistant"
                />
                <Feature
                  url="/image-generator"
                  image={featureImageGeneratorImage}
                  icon={<BurstModeOutlined />}
                  title="Image Generator"
                  id="image-generator"
                />

                <Feature
                  url="/game-ideator"
                  image={featureIdeatorImage}
                  icon={<WbIncandescentOutlined className="flip-vertical" />}
                  title="Game Ideator"
                  id="game-ideator"
                />
                <Feature
                  url="/game-concept"
                  image={featureConceptImage}
                  icon={<FileCopyOutlined />}
                  title="Game Concept"
                  id="game-concept"
                />
                <Feature
                  url="/market-analysis"
                  image={featureMarketAnalysisImage}
                  icon={<AssessmentOutlined />}
                  title="Market Analysis"
                  id="market-analysis"
                />

                <Feature
                  url="/top-charts"
                  image={featureTopChartsImage}
                  icon={<EmojiEventsOutlined />}
                  title="Top Charts Blender"
                  id="top-charts-blender"
                />
                <Feature
                  url="/search"
                  image={featureSearchImage}
                  icon={<SearchOutlined />}
                  title="Search"
                  id="search"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Feature = ({ url, image, icon, title, id }) => {
  const { track } = useContext(SocketContext);
  const history = useHistory();

  function onClick() {
    track("hq.feature." + id);
    history.push(url);
  }

  return (
    <div className="feature hvr-grow" onClick={onClick}>
      <div className="feature-bg" />
      {image && <img alt={title} className="feature-image" src={image} />}
      <MyButton className="clear round">
        {icon}
        {title}
      </MyButton>
    </div>
  );
};

const ThemedBox = ({ line1, line2, line3, actions, bg, style }) => {
  let className = "themed-box";
  return (
    <div className={className} style={style}>
      {backgroundImage && (
        <div className="bg-image" style={{ backgroundImage: `url(${bg})` }} />
      )}
      <div className="bg-overlay" />
      <div className="content-wrapper">
        <div className="info">
          <span className="line1">{line1}</span>
          <span className="line2">{line2}</span>
          <span className="line3">{line3}</span>
        </div>
        <div className="actions">{actions}</div>
      </div>
    </div>
  );
};

const TAB_OPTIONS = [
  { label: "PDF", icon: <PictureAsPdfOutlined/> },
  { label: "Text", icon: <NotesOutlined/> },
];

export const ImportConcept = ({ onClose }) => {
  const className = "import-concept-modal no-padding";

  const { track } = useContext(SocketContext);
  const { auth } = useContext(AuthContext);
  const { call, loading } = useContext(APIContext);
  const { cache, setCache } = useContext(CacheContext);
  const { gddComponents } = cache;

  const history = useHistory();

  const { summary, images, score } = gddComponents;

  const defaultGenres = (auth.user.genres || []).filter(
    (genre) => genre !== "All"
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [genres, setGenres] = useState(defaultGenres);
  const [perspective, setPerspective] = useState(undefined);
  const [artStyle, setArtStyle] = useState();
  const [platform, setPlatform] = useState(auth.user.platform || "Mobile");
  const [showPacks, setShowPacks] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (showPacks) {
      track("import-concept.show-packs");
    }
  }, [showPacks]);

  function prepareGDD() {
    const id = PerformanceUtils.generateId();
    const gdd = {
      art_style: artStyle,
      perspective,
      genres,
      platform,
      sections: [
        {
          name: summary.section,
          value: { text: description, title, id, _id: id },
        },
        {
          name: score.section,
          value: { title: "" },
        },
      ],
    };

    if (uploadedImages.length > 0) {
      gdd.sections.push({
        name: images.section,
        value: { images: uploadedImages },
      });
    }

    return gdd;
  }

  async function onSubmitPdf() {
    let response = await call("importGameConceptPDF", { file: [pdfs[0]] });
    if (response.ok) {
      setCache((prevState) => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(
            response.body,
            prevState.projects,
            "_id"
          ),
          selectedProjectId: response.body._id,
        };
      });
      history.push("/game-concept");
    }
  }

  function onSubmit() {
    setShowPacks(true);
  }

  const truncateToWordLimit = (text, limit) => {
    const words = text.trim().split(/\s+/);
    if (words.length > limit) {
      return words.slice(0, limit).join(" ");
    }
    return text;
  };

  const handleDescriptionChange = (e) => {
    const truncatedText = truncateToWordLimit(e.target.value, 1000);
    setDescription(truncatedText);
  };

  function addImages(images) {
    track("import-concept.add-images", { number: images.length });
    setUploadedImages(images);
  }

  const noDescription = !description.trim();
  const numberWords = description
    .trim()
    .split(/\s+/)
    .filter((w) => !!w).length;

  return showPacks ? (
    <NewGDDModal
      open={true}
      gdd={prepareGDD()}
      onClose={() => setShowPacks(false)}
      setLoading={() => {}}
    />
  ) : (
    <GDDModal
      open={true}
      onClose={onClose}
      title="Import Your Game Concept by..."
      className={className}
    >
      <div
        className={
          "import-concept-form " + TAB_OPTIONS[tab].label.toLowerCase()
        }
      >
        <FormTabs
          value={tab}
          className="secondary"
          onChange={(event, newValue) => setTab(newValue)}
        >
          {TAB_OPTIONS.map(({ label, icon }, index) => (
            <FormTab
              key={label}
              label={
                <span>
                  {icon} {label}
                </span>
              }
            />
          ))}
        </FormTabs>

        {tab === 1 && (
          <>
            <div className="forms-wrapper">
              <div className="fields-wrapper">
                <div className="genres-wrapper">
                  <div className="platform-selection">
                    <AddPlatform
                      selected={platform}
                      onSelect={(platform) => setPlatform(platform)}
                    />
                  </div>
                  <div className="platform-selection">
                    <AddArtStyle
                      selected={artStyle}
                      onSelect={(style) => setArtStyle(style)}
                    />
                  </div>
                  <div className="platform-selection">
                    <AddPerspective
                      selected={perspective}
                      onSelect={(perspective) => setPerspective(perspective)}
                    />
                  </div>
                  <MultipleGenres
                    selectedGenres={genres || []}
                    onDelete={(genre) =>
                      setGenres((prevState) =>
                        prevState.filter((g) => g !== genre)
                      )
                    }
                    onAdd={(genre) =>
                      setGenres((prevState) => [...prevState, genre])
                    }
                    size="large"
                  />
                </div>
                <TextField
                  className="text-field"
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  className="text-field"
                  label="Description"
                  value={description}
                  placeholder="Describe your game concept or game idea, providing all the details you already have."
                  multiline={true}
                  rows={5}
                  rowsMax={20}
                  onChange={handleDescriptionChange}
                  helperText={
                    noDescription
                      ? "Description is required"
                      : `${numberWords}/1000 words`
                  }
                  error={!description.trim()}
                />
                <MyButton
                  onClick={onSubmit}
                  disabled={!description.trim()}
                  className="secondary"
                >
                  <FileUploadOutlined /> Import Concept
                </MyButton>
              </div>
              <div className="add-images">
                <UploadImage
                  addImages={addImages}
                  uploadText="Drag & Drop Images"
                  hideFiles={false}
                />
              </div>
            </div>
          </>
        )}
        {tab === 0 && (
          <div className="add-images">
            <FileUpload
              hideFiles={true}
              onFilesUpdated={setPdfs}
              accept="application/pdf"
              uploadText="Drag & Drop PDF"
              title=""
              preview={
                pdfs.length > 0 && (
                  <div className="dropzone">
                    <div className="dropzone-upload-wrapper">
                      <div className="dropzone-inner-wrapper cursor-default p-1">
                        <div className="instructions">
                          <div className="icon-wrapper">
                            <div className="btn-icon mb-2 text-white">
                              <PictureAsPdfOutlined className="pdf-icon" />
                            </div>
                          </div>
                          <span>{pdfs[0].name}</span>
                          <GeneratingButton
                            id="import.pdf"
                            className="secondary browse mt-3"
                            onClick={onSubmitPdf}
                            loading={loading["importGameConceptPDF"]}
                            loadingText="Parsing your document, please wait..."
                            loadProgressSecs={30}
                          >
                            Import PDF
                          </GeneratingButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              hideDropZone={pdfs.length > 0}
            />
          </div>
        )}
        <div className="spacer">&nbsp;</div>
      </div>
    </GDDModal>
  );
};

export default Headquarters2;
